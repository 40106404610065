.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}

.custom-table {
  .table.table-sm {
    td,
    th {
      padding: 0.6rem 0.6rem;
    }
  }
  .col-width {
    min-width: 300px;
  }
  .custom-table-col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (max-width: 480px) {
    .col-width {
      width: 300px;
    }
    .react-bs-table table {
      table-layout: auto;
    }

    .hidden-xs {
      display: none;
    }
  }
  .col-header {
    font-size: 13px;
    font-weight: 700;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .row-even {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .row-odd {
    background-color: #efefef;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .header-row {
    box-shadow: 0px 1px 3px rgba(69, 90, 100, 0.3);
    z-index: 1;
    position: relative;
    // border-top-left-radius: 10px;
    background-color: #ecf0f5;
    // border-top-right-radius: 10px;
  }
  .header-row-fixed {
    position: fixed;
    width: calc(100% - 270px);
    @media only screen and (max-width: 992px) {
      width: calc(100% - 40px);
    }
  }
  .header-row-fixed-collapsed {
    width: calc(100% - 115px);
  }

  .status-container {
    display: block;
    text-align: center;
    @media only screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .status-live {
    background-color: #fff;
    padding: 0 3px;
    color: green;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    border: 1px dotted green;
  }
  .status-pending {
    background-color: #fff;
    padding: 0 3px;
    color: orange;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    border: 1px dotted orange;
  }
  .status-delivered {
    background-color: #fff;
    padding: 0 3px;
    color: #00acc1;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    border: 1px dotted #00acc1;
  }

  .status-unapproved {
    background-color: #fff;
    padding: 0 3px;
    color: red;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    border: 1px dotted red;
  }

  .status-live-border-less {
    padding: 0 3px;
    color: green;
    text-align: left;
    font-size: 12px;
  }
  .status-pending-border-less {
    padding: 0 3px;
    color: orange;
    text-align: left;
    font-size: 12px;
  }
  .status-delivered-border-less {
    padding: 0 3px;
    color: #00acc1;
    text-align: left;
    border-radius: 5px;
    font-size: 12px;
  }

  .status-unapproved-border-less {
    padding: 0 3px;
    color: red;
    text-align: left;
    border-radius: 5px;
    font-size: 12px;
  }

  .no-data-found {
    font-size: 16px;
    margin: 10px;
  }
  .platform-label {
    color: #5fba7d;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    & .platform-label-item {
      margin: 0px 5px;
    }
    & .platform-label-item::after {
      content: ', ';
      display: inline-block;
    }
    & .platform-label-item:last-of-type::after {
      display: none;
    }
  }
  .btn-info {
    color: #5674b8;
    background-color: #5674b824;
    padding: 0.15rem 0.5rem;
    border-color: #5674b8;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
  div.row > [class^='col'] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sort-asc::after {
    font-family: feather !important;
    content: '\e82a';
    display: inline-block;
    width: 0;
  }
  .sort-desc::after {
    font-family: feather !important;
    content: '\e82d';
    display: inline-block;
    width: 0;
  }
  .col-sort {
    cursor: pointer;
  }
}
.visible-xs {
  display: none;
}
.visible-xs-md {
  display: none;
}

@media (max-width: 600px) {
  .hidden-sm {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}

@media (max-width: 480px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}
@media (max-width: 768px) and (min-width: 480px) {
  .hidden-xs-md {
    display: none;
  }
  .col-xs-md-4 {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .col-xs-md-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .visible-xs-md {
    display: block;
  }
  .col-xs-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xs-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 1024px) and (min-width: 992px) {
  .hidden-lg {
    display: none;
  }
  .visible-lg {
    display: block;
  }
}

.pcoded-content {
  padding: 0px;
}
.page-container {
  margin: 20px;
  @media (max-width: 992px) {
    padding-top: 35px !important;
  }
}

.custom-btn {
  font-size: 14px;
  font-weight: 400;
}

.isrc-btns {
  position: relative;
  .gen-isrc {
    white-space: nowrap;
  }
  .buy-isrc {
    @media (max-width: 767px) {
      position: absolute;
      right: 0px;
      top: 77px;
      width: calc(100vw - 40px);
    }
  }
}
.isrc-explicit-flag {
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}

.upc-explicit-flag {
  margin-top: 29px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}

.dropzone.dz-clickable * {
  cursor: pointer;
}

// .pcoded-wrapper {
//   overflow: hidden;
// }

.image-video-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  width: 350px;
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
  }
}

body {
  ::placeholder {
    color: #ccc !important;
  }
}

.macv-page-container {
  height: calc(100% - 56px);
  min-height: calc(100vh - 56px);
  margin: 0px;
  padding: 20px;
  background-color: #fff;
}

.custom-alert-warning {
  color: #fff !important;
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.custom-alert-success {
  color: #fff !important;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.black-download-btn {
  color: #000;
  background-color: transparent;
  border-color: #000;
}
